import React, { useEffect, useState } from "react";
import PageHeader from "../components/common/PageHeader";
import { useTranslation } from "react-i18next";
import Layout from "../components/common/Layout";
import { graphql } from "gatsby";
import logo from "../images/logo.png";
import { getImage, StaticImage } from "gatsby-plugin-image";
import Seo from "../components/default/seo";

// todo: add logos
export default function Partners({ data }) {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <Layout>
      <Seo title={t("Contact Us")} lang={language} />
      <PageHeader title={t("Partners")} />
      <section className="m-12 flex max-w-5xl flex-row items-start justify-between lg:my-24 lg:mx-auto">
        <div className="mb-0 grid grid-cols-1 gap-24 md:mb-24 md:grid-cols-2">
          <div>
            <div className="mb-8 flex flex-row items-center gap-12">
              <StaticImage
                src="../images/partners/sostanza.png"
                alt="Sostanza Logo"
                layout="fixed"
                width={76}
                height={76}
                placeholder="none"
              />
              <h1 className="text-3xl"><a href="https://www.sostanzaglobal.com/" target="_blank">Sostanza</a></h1>
            </div>
            <p className="text-textBlack">{t("Sostanza")}</p>
          </div>
		  
		  <div>
            <div className="mb-8 flex flex-row items-center gap-12">
              <StaticImage
                src="../images/partners/cropped-friedberg-logom-1.png"
                alt="Friedberg Logo"
                layout="fixed"
                width={76}
                height={76}
                placeholder="none"
              />
              <h1 className="text-3xl"><a href="https://friedbergsa.com" target="_blank">Friedberg Scientific Advisors</a></h1>
            </div>
            <p className="text-textBlack">{t("Friedberg")}</p>
          </div>
		  
{/*
          <div className="relative w-full">
            <StaticImage
              src="../images/partners/365.png"
              alt="365 Cannabis Logo"
              layout="fullWidth"
              width={406}
              height={76}
              className="mb-8 w-full max-w-[406px]"
              objectFit="scale-down"
              placeholder="none"
            />
            <p className="text-textBlack">{t("Cannabis")}</p>
          </div>
          <div>
            <StaticImage
              src="../images/partners/c15.png"
              alt="C15 Logo"
              layout="fixed"
              width={76}
              height={76}
              className="mb-8 block w-10"
              placeholder="none"
            />
            <p className="text-textBlack">{t("Veeva")}</p>
          </div>
*/}
        </div>
      </section>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { language: { eq: $language }, ns: { in: ["common", "support"] } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
